import { TextField } from "@mui/material";
import axios, { AxiosError } from "axios";
import { useEffect, useState } from "react";
import { Button as BootstrapBtn, Spinner } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router";

import { BASE_API } from "features/kaidu-config-server";
import { getLocalStorage } from "lib/persistence/local-storage";

export function OTPLogin({ onError, ...rest }) {
  const history = useHistory();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [isLoading, setLoading] = useState<boolean>(false);
  const partner_customer_id = getLocalStorage("partner_customer_id");

  const onSubmit = (data) => {
    setLoading(true);
    axios
      .post(`${BASE_API}/auth/request_otp`, {
        email: data.email,
        isManagementDashboard: false,
        partner_customer_id,
      })
      .then((response) => {
        setLoading(false);
        if (response.data) {
          onError("");
          history.push("/verify_otp", { email: data.email });
        }
      })
      .catch((err: AxiosError<any>) => {
        const errMsg = err?.response?.data?.message;
        onError(errMsg || err?.message || "Failed to request OTP");
        setLoading(false);
      });
  };

  useEffect(() => {
    if (!errors.email) onError(null);
    else {
      const { type } = errors.email;
      onError(type === "required" ? "Email is required" : "Email is invalid");
    }
  }, [onError, errors.email]);

  return (
    <>
      <div className="flex flex-col gap-2" {...rest}>
        <TextField
          placeholder="test@email.com"
          fullWidth
          type="email"
          size="small"
          {...register("email", {
            required: true,
            pattern: /^([a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/,
          })}
        />
        <BootstrapBtn
          className="w-full"
          onClick={handleSubmit(onSubmit)}
          disabled={isLoading}
        >
          {isLoading && (
            <Spinner animation="border" size="sm" className="mr-2" />
          )}
          Sign In With Email
        </BootstrapBtn>

        {/* <Alert icon={false} severity="info" className="w-full">
          We'll email you a magic code for a password-free sign in
        </Alert> */}
      </div>
    </>
  );
}
